import React from "react"
import { Container, Row, Col } from "react-grid-system"
import styled from "styled-components"
import Header from "../components/header"
import Layout from "../components/layout"
import SEO from "../components/seo"
import heroImage from "../images/illu-baloon.jpg"
import { Display, HeadingSmall, Body } from "../components/typography"
import { BtnLink } from "../components/button"

const Success = () => {
  return (
    <Layout>
      <SEO title="Iscrizione confermata" />
      <Header />
      <Wrapper>
        <CustomContainer>
          <Row justify="center">
            <Col md={12} lg={9} xl={9}>
              <Content>
                <Display>
                  La tua guida
                  <br />è stata inviata!
                </Display>
                <HeadingSmall>
                  Arriverà a breve nella tua casella di posta
                </HeadingSmall>
                <BtnLink to="/questionario/">Inizia il test</BtnLink>
              </Content>
            </Col>
          </Row>
        </CustomContainer>
      </Wrapper>
    </Layout>
  )
}

const CustomContainer = styled(Container)`
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
  padding-bottom: 15rem;
  & > * {
    margin-bottom: 3rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`
const Wrapper = styled.div`
`

const Content = styled.div`
  & > * {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default Success
